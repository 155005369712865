import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../common/containers/Modal';
import Button from '../../../common/components/Button';
import CKEditor from '../../ckeditor/CKEditor';
import Config from '../../../config';
import Ajax from '../../../common/ajax';
import HtmlUtils from '../../../common/htmlutils';
import Events from '../Events';
import { withNamespaces } from 'react-i18next';
import MathJaxPreview from '../../../common/components/MathJaxPreview';
import { showDjangoValidationMessages } from '../../../common/validation';
import Formula from '../formula/Formula';

class Symbol extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      processing: false,
      showForm: false,
      symbols: [],
      editorInstance: null,
      form: {
        id: null,
        symbol: '',
        description: '',
      },
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.toggleNewSymbolForm = this.toggleNewSymbolForm.bind(this);
    this.fetchSymbols = this.fetchSymbols.bind(this);
    this.deleteSymbol = this.deleteSymbol.bind(this);
    this.editSymbol = this.editSymbol.bind(this);
    this.save = this.save.bind(this);
  }

  openModal() {
    this.fetchSymbols();
    this.setState((state) => ({ ...state, showModal: true }));
  }

  closeModal() {
    this.setState((state) => ({ ...state, showModal: false }));
  }

  fetchSymbols() {
    this.setState((state) => ({ ...state, processing: true }));
    const url = `${Config.apiHost}symbols/?document=${this.props.document.id}`;
    Ajax.get(url)
      .done((data) => {
        this.setState((state) => {
          return {
            ...state,
            symbols: data,
            showForm: false,
            form: {},
          };
        });
      })
      .always(() => {
        this.setState((state) => ({ ...state, processing: false }));
      });
  }

  toggleNewSymbolForm() {
    this.setState((state) => ({
      ...state,
      showForm: !state.showForm,
      form: {},
    }));
  }

  save() {
    this.setState((state) => ({ ...state, processing: true }));
    let url, jqXHR;
    const data = {
      symbol: this.state.editorInstance.getData(),
      description: this.state.form.description,
    };

    if (this.state.form.id) {
      url = `${Config.apiHost}symbols/${this.state.form.id}/`;
      jqXHR = Ajax.patch(url, data);
    } else {
      url = `${Config.apiHost}symbols/`;
      data.document = this.props.document.id;
      jqXHR = Ajax.post(url, data);
    }

    jqXHR
      .done(() => {
        this.fetchSymbols();
        this.props.editor.fire(Events.COMPILE);
      })
      .fail((_jqXHR) => {
        showDjangoValidationMessages(_jqXHR.responseJSON);
      })
      .always(() => {
        this.setState((state) => ({ ...state, processing: false }));
      });
  }

  editSymbol(symbol) {
    this.setState((state) => {
      return {
        ...state,
        showForm: true,
        form: { ...symbol },
      };
    });
  }

  deleteSymbol(symbol) {
    const { i18n } = this.props;
    const confirmed = confirm(i18n.t('Deseja realmente excluir esse símbolo?'));
    if (confirmed) {
      const url = `${Config.apiHost}symbols/${symbol.id}/`;
      Ajax.delete_(url).done(() => {
        this.fetchSymbols();
      });
    }
  }

  render() {
    const { i18n } = this.props;
    return (
      <>
        <a
          role="button"
          onClick={this.openModal}
          title={i18n.t('Editar símbolos do documento')}
        >
          <span className="mdi mdi-functions" />
          &nbsp;
          <span>{i18n.t('Símbolos')}</span>
        </a>

        <Modal
          title={i18n.t('Símbolos')}
          show={this.state.showModal}
          width="large"
          isProcessing={this.state.processing}
          onCancel={this.closeModal}
        >
          {!this.state.showForm && (
            <Button
              type="primary"
              size="md"
              onClick={this.toggleNewSymbolForm}
              className="pull-right"
            >
              {i18n.t('Novo')}
            </Button>
          )}

          {!this.state.showForm && (
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>{i18n.t('Símbolo')}</th>
                  <th>{i18n.t('Descrição')}</th>
                  <th width="1%" />
                  <th width="1%" />
                </tr>
              </thead>
              <tbody>
                {this.state.symbols.map((symbol) => {
                  const formula = HtmlUtils.stripHtmlTags(symbol.symbol);
                  return (
                    <tr key={symbol.id}>
                      <td>
                        <MathJaxPreview math={formula} />
                      </td>
                      <td>{symbol.description}</td>
                      <td>
                        <a
                          role="button"
                          className="btn btn-link btn-lg"
                          onClick={() => this.editSymbol(symbol)}
                        >
                          <i className="icon mdi mdi-edit" />
                        </a>
                      </td>
                      <td>
                        <a
                          role="button"
                          className="btn btn-link btn-lg"
                          onClick={() => this.deleteSymbol(symbol)}
                        >
                          <i className="icon mdi mdi-delete" />
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}

          {this.state.showForm && (
            <div>
              <div className="form-group">
                <label className="control-label  " htmlFor="id_symbol">
                  {i18n.t('Símbolo')}
                  <span className="text-danger">*</span>
                </label>

                <div className=" ">
                  <CKEditor
                    id="id-ckeditor-symbol"
                    content={this.state.form.symbol}
                    config={{
                      toolbar: 'Symbol',
                      extraPlugins:
                        'divarea,fastformatLink,fastformatFormula,fastformatCleaner,fastformatDisableGrammarly',
                      height: '50px',
                      allowedContent:
                        'strong b em i u strike s sub sup q p span(*)',
                      on: {
                        instanceReady: (evt) => {
                          evt.editor.config.fastformatMathjax_disableNumbering = true;
                          this.setState((state) => ({
                            ...state,
                            editorInstance: evt.editor,
                          }));
                        },
                      },
                    }}
                    languageCode={i18n.language}
                  />
                  <p className="help-block">Um síbolo, como "t"</p>
                </div>
              </div>
              <div className="form-group">
                <label
                  className="control-label  "
                  htmlFor="id_symbol_description"
                >
                  {i18n.t('Descrição')}
                  <span className="text-danger">*</span>
                </label>
                <div className=" ">
                  <input
                    type="text"
                    name="description"
                    id="id_symbol_description"
                    required={true}
                    className="form-control"
                    maxLength="500"
                    value={this.state.form.description}
                    onChange={(e) => {
                      e.persist();
                      this.setState((state) => {
                        return {
                          ...state,
                          form: {
                            ...state.form,
                            symbol: state.editorInstance.getData(),
                            description: e.target.value,
                          },
                        };
                      });
                    }}
                  />
                  <p className="help-block">
                    {i18n.t('Texto descrevendo o símbolo')}
                  </p>
                </div>
              </div>
              <Button type="primary" size="lg" onClick={this.save}>
                {i18n.t('Salvar')}
              </Button>
              &nbsp;
              <Button
                type="default"
                size="lg"
                onClick={this.toggleNewSymbolForm}
              >
                {i18n.t('Cancelar')}
              </Button>
            </div>
          )}
        </Modal>

        {this.props.document && this.state.editorInstance && (
          <Formula
            key={this.state.editorInstance.id + '-formula'}
            document={this.props.document}
            editor={this.state.editorInstance}
            showNumbering={false}
          />
        )}
      </>
    );
  }
}

Symbol.propTypes = {
  document: PropTypes.object.isRequired,
  editor: PropTypes.object.isRequired,
};

export default withNamespaces()(Symbol);
