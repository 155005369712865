import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Modal from "../../common/containers/Modal";
import Config from "../../config";
import Ajax from "../../common/ajax";
import {withNamespaces} from "react-i18next";
import Button from "../../common/components/Button";

class Statistics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            statistics: null,
            show: false,
            processing: false,
            error: false,
        };
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
    }


    open() {
        this.setState(state => ({
            ...state,
            processing: true,
            show: true,
            error: false,
        }));
        const url = `${Config.apiHost}documents/${this.props.documentId}/statistics/`;
        Ajax.get(url).done(statistics => {
            this.setState(state => ({
                ...state,
                statistics,
                processing: false,
            }));
        }).fail(() => {
            this.setState(state => ({
                ...state,
                processing: false,
                error: true,
            }));
        });
    }

    close() {
        this.setState(state => ({...state, show: false}));
    }

    render() {
        const {i18n} = this.props;
        return (
            <React.Fragment>
                <a role="button" onClick={this.open}>
                    <i className="icon mdi mdi-chart"/>
                    &nbsp;
                    {this.props.showLabel && i18n.t('Estatísticas')}
                </a>
                <Modal
                    show={this.state.show}
                    title={i18n.t('Estatísticas do documento')}
                    onCancel={this.close}
                    isProcessing={this.state.processing}
                    footer={
                        <Button onClick={this.close} size="lg">
                            {i18n.t('Fechar')}
                        </Button>
                    }
                >
                    {this.state.statistics &&
                        <table className="table">
                            <thead>
                                <tr>
                                    <th/>
                                    <th>{i18n.t('Caracteres')}</th>
                                    <th>{i18n.t('Palavras')}</th>
                                </tr>
                            </thead>
                            <tbody>
                            {Object.keys(this.state.statistics).map(attr => {
                                return (
                                    <tr>
                                        <td>{attr}</td>
                                        <td>{this.state.statistics[attr]['characters']}</td>
                                        <td>{this.state.statistics[attr]['words']}</td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>}
                </Modal>
            </React.Fragment>
        );
    }
}

Statistics.propTypes = {
    documentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    showLabel: PropTypes.bool,
};

Statistics.defaultProps = {
    showLabel: true,
};

export default withNamespaces()(Statistics);
