import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import Config from "../../../config";
import Ajax from "../../../common/ajax";
import Modal from "../../../common/containers/Modal";
import Button from "../../../common/components/Button";
import {withNamespaces} from "react-i18next";
import URLS from "../../../urls";
import Events from "../Events";
import {AppContext, isStaff, isSuperuser} from "../../../context/global";
import debounce from "lodash.debounce";

class LockIndicator extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            processing: false,
            lockAcquired: true,
            userWithLock: null,
            timeoutClient: null,
            closedForStaff: false,
        };

        this.releaseLock = this.releaseLock.bind(this);
        this.acquireLock = this.acquireLock.bind(this);
        this.debouncedAcquireLock = debounce(this.acquireLock, 2000);
        this.forceAcquireLock = this.forceAcquireLock.bind(this);

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);

        this._mounted = false;
    }

    componentDidMount() {
        this._mounted = true;
        $(window).unbind('beforeunload.ffLock').on('beforeunload.ffLock', () => {
            this.releaseLock();
        });

        if (queryString.parse(location.search).aclk === '1') {
            this.forceAcquireLock();
        } else {
            this.acquireLock();
        }

        this.props.editor.on('change', () => {
            this.debouncedAcquireLock();
        });

        document.addEventListener("keydown", () => {
            this.debouncedAcquireLock();
        });
    }

    componentWillUnmount() {
        this._mounted = false;
        this.releaseLock();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.editor) {
            return;
        }

        if (!prevState.lockAcquired && this.state.lockAcquired) {
            this.props.editor.fire(Events.CHANGE_ATTRIBUTE);
        }

        if (!this.state.lockAcquired) {
            this.props.editor.setReadOnly(true);
        } else {
            this.props.editor.setReadOnly(false);
        }
    }

    setStateIfMounted(state, callback) {
        if (this._mounted) {
            this.setState(state, callback);
        }
    }

    openModal() {
        this.setStateIfMounted(state => ({
            ...state,
            showModal: true,
        }));
    }

    closeModal() {
        if (isStaff() || isSuperuser()) {
            this.setStateIfMounted(state => ({
                ...state,
                closedForStaff: true,
            }));
        } else {
            document.location = URLS.myDocuments;
        }
    }

    acquireLock(force=false) {
        const url = `${Config.apiHost}documents/${this.props.document.id}/lock_polling/?force=${force}&uuid=${this.context.uuid}`;
        return Ajax.get(url).done(data => {
            this.setStateIfMounted(state => ({
                ...state,
                ...data,
            }));
        });
    }

    forceAcquireLock() {
        this.acquireLock(true);
    }

    releaseLock() {
        const url = `${Config.apiHost}documents/${this.props.document.id}/lock_polling/?uuid=${this.context.uuid}`;
        return Ajax.deleteSync(url);
    }

    renderModalFooter() {
        const {i18n} = this.props;
        return (
            <React.Fragment>
                <Button size="lg"
                        type="default"
                        onClick={this.closeModal}>
                    {i18n.t('Fechar')}
                </Button>
                <Button type="primary"
                        size="lg"
                        processing={this.state.processing}
                        onClick={this.forceAcquireLock}>
                    {i18n.t('Assumir edição')}
                </Button>
            </React.Fragment>
        );
    }

    render() {
        if (this.state.lockAcquired || this.state.closedForStaff) {
            return null;
        }

        const {i18n} = this.props;
        return (
            <React.Fragment>
                <Modal
                    title={i18n.t('O documento está sendo editado por outra pessoa')}
                    show={!this.state.lockAcquired}
                    isProcessing={this.state.processing}
                    footer={this.renderModalFooter()}
                >
                    <p>
                        {i18n.t('Este documento está sendo editado por')} <b>{this.state.userWithLock.email}</b>.
                    </p>
                    <p>
                        {i18n.t('Se for você mesmo, certifique-se de que não tem outra janela aberta com esse mesmo documento.')}
                    </p>
                    <p>
                        {i18n.t('Entretanto, você pode forçar o modo de edição clicando no botão a seguir.')}
                    </p>
                </Modal>
            </React.Fragment>
        );
    }
}

LockIndicator.propTypes = {
    document: PropTypes.object.isRequired,
    editor: PropTypes.object.isRequired,
};

LockIndicator.contextType = AppContext;

export default withNamespaces()(LockIndicator);