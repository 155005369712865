import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../common/containers/Modal';
import Config from '../../../config';
import Ajax from '../../../common/ajax';
import CKEditor from '../../ckeditor/CKEditor';
import Button from '../../../common/components/Button';
import Events from '../Events';

import './custom.css';
import { AlertError, AlertSuccess } from '../../../common/components/Alert';

import { withNamespaces } from 'react-i18next';
import { showDjangoValidationMessages } from '../../../common/validation';

function showErrors(errors) {
  const newErrors = {};
  Object.keys(errors).forEach((key) => {
    newErrors[key] = errors[key].map((obj) => obj.message);
  });
  showDjangoValidationMessages(newErrors);
}

class Identification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.document.title,
      showModal: false,
      showEdit: false,
      processing: false,
      editorReady: false,
      success: null,
      error: null,
      htmlForm: '',
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onCKEditorInstanceReady = this.onCKEditorInstanceReady.bind(this);
    this.save = this.save.bind(this);
    this.clearMessages = this.clearMessages.bind(this);
    this.fetchForm = this.fetchForm.bind(this);
    this.ckEditorInstance = null;
    this.formRef = React.createRef();
  }

  openModal() {
    this.setState((state) => ({
      ...state,
      processing: true,
      showModal: true,
      success: null,
      error: null,
    }));

    this.fetchForm();
  }

  fetchForm() {
    const url = `${Config.djangoHost}e/attrs/${this.props.document.id}`;
    Ajax.get(url).done((htmlForm) => {
      const div = document.createElement('div');
      div.innerHTML = htmlForm;
      $(div).find('[type="file"]').closest('.form-group').addClass('well well-sm');
      this.setState((state) => ({
        ...state,
        processing: false,
        htmlForm: div.innerHTML,
      }));
    });

    // Reload the document title.
    const urlDoc = `${Config.apiHost}documents/${this.props.document.id}/`;
    Ajax.get(urlDoc, 'title').done((data) => {
      this.setState((state) => ({ ...state, title: data.title }));
    });
  }

  closeModal() {
    this.setState((state) => ({
      ...state,
      showModal: false,
    }));
  }

  clearMessages() {
    this.setState((state) => {
      return {
        ...state,
        success: null,
        error: null,
      };
    });
  }

  onCKEditorInstanceReady(evt) {
    this.ckEditorInstance = evt.editor;
    this.setState((state) => ({
      ...state,
      editorReady: true,
    }));
  }

  save() {
    const { i18n } = this.props;
    const title = this.ckEditorInstance.getData();

    this.setState((state) => ({
      ...state,
      title,
      processing: true,
      success: null,
      error: null,
    }));

    const form = document.getElementById('id-identification-form');
    const formData = new FormData(form);
    formData.append('title', title);
    const url = `${Config.djangoHost}e/attrs/${this.props.document.id}`;
    Ajax.postFormData(url, formData)
      .done((response) => {
        if (response.errors) {
          this.setState((state) => ({
            ...state,
            error: i18n.t('As informações não poderam ser salvas. Verifique os erros abaixo.'),
          }));
          showErrors(response.errors);
        } else {
          this.setState((state) => ({
            ...state,
            success: i18n.t('As informações foram salvas com sucesso!'),
          }));
          this.props.editor.fire(Events.COMPILE);
          this.props.editor.fire(Events.AFTER_IDENTIFICATION_SAVE);
          this.fetchForm();
        }
      })
      .fail((jqXHR) => {
        this.setState((state) => ({
          ...state,
          error: i18n.t('As informações não poderam ser salvas.'),
        }));
        showDjangoValidationMessages(jqXHR.responseJSON);
      })
      .always(() => {
        const modalBody = $(this.formRef.current).closest('.modal-body')[0];
        if (modalBody) {
          modalBody.scrollTop = 0;
        }
        this.setState((state) => ({
          ...state,
          processing: false,
        }));
      });
  }

  render() {
    const { i18n } = this.props;
    let label;

    if (this.props.document.template_meta.is_book) {
      label = i18n.t('Capa e folha de rosto');
    } else {
      label = i18n.t('Identificação');
    }

    const footer = (
      <React.Fragment>
        <Button type="default" size="lg" onClick={this.closeModal}>
          {i18n.t('Fechar')}
        </Button>
        <Button type="primary" size="lg" onClick={this.save}>
          {i18n.t('Salvar')}
        </Button>
      </React.Fragment>
    );

    return (
      <React.Fragment>
        {this.props.children && (
          <span
            style={{ cursor: 'pointer' }}
            onClick={this.openModal}
            onMouseOver={() => this.setState((state) => ({ ...state, showEdit: true }))}
            onMouseOut={() => this.setState((state) => ({ ...state, showEdit: false }))}
          >
            {this.props.children} {this.state.showEdit && <i className="icon mdi mdi-edit text-primary" />}
          </span>
        )}

        {!this.props.children && (
          <a
            role="button"
            onClick={this.openModal}
            id="id-identification-button"
            title={i18n.t('Editar o título e outras informações')}
          >
            <span className="mdi mdi-book" />
            &nbsp;
            <span>{label}</span>
          </a>
        )}

        <Modal
          title={label}
          show={this.state.showModal}
          isProcessing={this.state.processing || !this.state.editorReady}
          width="large"
          onCancel={this.closeModal}
          footer={footer}
          bodyMinHeight="calc(70vh)"
        >
          <div className="form-group">
            <AlertSuccess onClose={this.clearMessages}>{this.state.success}</AlertSuccess>
            <AlertError onClose={this.clearMessages}>{this.state.error}</AlertError>

            <label className="control-label" htmlFor="ckeditor-title">
              Título
            </label>
            <CKEditor
              id="ckeditor-title"
              content={this.state.title}
              languageCode={i18n.language}
              config={{
                toolbar: 'VeryBasic',
                enterMode: CKEDITOR.ENTER_BR,
                readOnly: false,
                height: 80,
                disallowedContent: 'p div',
                extraPlugins: [
                  'fastformatProofread',
                  'fastformatCleaner',
                  'fastformatLink',
                  'fastformatDisableGrammarly',
                  'divarea',
                  'devtools',
                  'codemirror',
                ].join(','),
                on: {
                  instanceReady: this.onCKEditorInstanceReady,
                },
              }}
            />
          </div>

          <form id="id-identification-form" ref={this.formRef}>
            <div dangerouslySetInnerHTML={{ __html: this.state.htmlForm }} />
          </form>
        </Modal>
      </React.Fragment>
    );
  }
}

Identification.propTypes = {
  document: PropTypes.object.isRequired,
  editor: PropTypes.object.isRequired,
};

export default withNamespaces()(Identification);
