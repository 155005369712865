import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import Ajax from '../../../common/ajax';
import { AlertError } from '../../../common/components/Alert';
import Button from '../../../common/components/Button';
import Modal from '../../../common/containers/Modal';
import storage from '../../../common/storage';
import Config from '../../../config';
import {
  AppContext,
  hasProofread,
  hasSpellchecker,
} from '../../../context/global';
import PlanExpiration from '../../../sales/billing/PlanExpirationButton';
import Events from '../Events';
import './editor.nlp.css';
import './editor.nlp.proofread';
import FastFormatEditorNLPGrammar from './editor.nlp.proofread';

class Proofread extends Component {
  constructor(props) {
    super(props);
    this.langStorageKey = 'ff_proofread_lang';
    this.state = {
      showLanguageModal: false,
      processingLanguageModal: false,
      languages: [],
      specificElement: null,
      selectedLanguage: storage.getItem(this.langStorageKey, ''),
      showPermissionModal: false,
      fetchLanguagesError: false,
    };
    this.openLanguageModal = this.openLanguageModal.bind(this);
    this.closeLanguageModal = this.closeLanguageModal.bind(this);
    this.selectLanguage = this.selectLanguage.bind(this);
    this.verifyPermission = this.verifyPermission.bind(this);
    this.closePermissionModal = this.closePermissionModal.bind(this);
    this.proofreadAPI = null;
  }

  componentDidMount() {
    this.selectDocumentLanguageCode();

    this.proofreadAPI = new FastFormatEditorNLPGrammar(this);

    this.props.editor.on(Events.CHANGE_PROOFREAD_IDIOM, (evt) => {
      this.openLanguageModal();
    });

    this.props.editor.on(Events.CHANGE_ELEMENT_PROOFREAD_IDIOM, (evt) => {
      const specificElement = evt.data;
      this.openLanguageModal(specificElement);
    });

    this.props.editor.on(Events.PROOFREAD_STARTED, (evt) => {
      this.verifyPermission();
    });
  }

  selectDocumentLanguageCode() {
    const { language_code } = this.props.document;
    const currentLangInStorage = storage.getItem(this.langStorageKey);
    if (!currentLangInStorage || currentLangInStorage !== language_code) {
      storage.setItem(this.langStorageKey, language_code);
      this.setState((state) => ({
        ...state,
        selectedLanguage: language_code,
      }));
    }
  }

  openLanguageModal(specificElement = null) {
    this.setState((state) => ({
      ...state,
      showLanguageModal: true,
      processingLanguageModal: true,
      fetchLanguagesError: false,
    }));

    let selectedLang = storage.getItem(this.langStorageKey);
    if (specificElement && specificElement.getAttribute('data-lang')) {
      selectedLang = specificElement.getAttribute('data-lang');
    }

    const url = `${Config.apiHost}proofread/grammar-languages/`;
    Ajax.get(url)
      .done((languages) => {
        this.setState((state) => ({
          ...state,
          selectedLanguage: selectedLang,
          specificElement,
          languages,
        }));
      })
      .fail(() => {
        this.setState((state) => ({
          ...state,
          fetchLanguagesError: true,
        }));
      })
      .always(() => {
        this.setState((state) => ({
          ...state,
          processingLanguageModal: false,
        }));
      });
  }

  closeLanguageModal() {
    this.setState((state) => ({
      ...state,
      showLanguageModal: false,
    }));
  }

  selectLanguage(e) {
    e.persist();
    if (this.state.specificElement) {
      // Changing idiom for a single HTML element.
      const docLang = storage.getItem(this.langStorageKey);
      if (e.target.value === docLang) {
        this.state.specificElement.removeAttribute('data-lang');
      } else {
        this.state.specificElement.setAttribute('data-lang', e.target.value);
      }
      this.setState((state) => ({
        ...state,
        selectedLanguage: storage.getItem(this.langStorageKey),
        specificElement: null,
      }));
    } else {
      // Changing idiom for the whole document.
      const { i18n } = this.props;
      this.setState((state) => ({
        ...state,
        selectedLanguage: e.target.value,
      }));
      storage.setItem(this.langStorageKey, e.target.value);
      this.props.editor.getMenuItem('optionsMenuItem').label = `${i18n.t(
        'Alterar idioma'
      )} (${e.target.value})`;
    }
    this.proofreadAPI.restart();
  }

  verifyPermission() {
    if (!hasSpellchecker() || !hasProofread()) {
      this.setState((state) => ({
        ...state,
        showPermissionModal: true,
      }));
    } else if (this.state.showPermissionModal) {
      this.setState((state) => ({
        ...state,
        showPermissionModal: false,
      }));
    }
  }

  closePermissionModal() {
    this.setState((state) => ({
      ...state,
      showPermissionModal: false,
    }));
  }

  render() {
    const { i18n } = this.props;

    return (
      <>
        <Modal
          title={i18n.t('Selecione um idioma')}
          show={this.state.showLanguageModal}
          onCancel={this.closeLanguageModal}
          footer={
            <Button size="lg" type="primary" onClick={this.closeLanguageModal}>
              {i18n.t('Fechar')}
            </Button>
          }
          isProcessing={this.state.processingLanguageModal}
        >
          {this.state.fetchLanguagesError && (
            <AlertError>
              {i18n.t('Não foi possível apresentar a lista de idiomas.')}{' '}
              {i18n.t('Se o problema persistir, contate nosso suporte.')}
            </AlertError>
          )}
          {!this.state.fetchLanguagesError && (
            <select
              className="form-control"
              value={this.state.selectedLanguage}
              onChange={this.selectLanguage}
            >
              {this.state.languages.map((l) => {
                return (
                  <option key={l.longCode} value={l.longCode}>
                    {l.name}
                  </option>
                );
              })}
            </select>
          )}
        </Modal>

        <Modal
          show={this.state.showPermissionModal}
          onCancel={this.closePermissionModal}
          renderInBody={false}
          title={i18n.t('Faça upgrade e melhore seu texto')}
          fullColor="primary"
        >
          {!hasSpellchecker() && (
            <h4>
              {i18n.t('Seu plano atual não dá suporte ao')}{' '}
              <b>{i18n.t('corretor ortográfico')}</b>.
            </h4>
          )}

          {!hasProofread() && (
            <h4>
              {i18n.t('Seu plano atual não dá suporte ao')}{' '}
              <b>{i18n.t('corretor gramatical e de estilo')}</b>.
            </h4>
          )}

          <br />

          <PlanExpiration />
        </Modal>
      </>
    );
  }
}

Proofread.propTypes = {
  document: PropTypes.object.isRequired,
  editor: PropTypes.object.isRequired,
};

Proofread.contextType = AppContext;

export default withNamespaces()(Proofread);
