import PropTypes from 'prop-types';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import { AlertSuccess } from '../../common/components/Alert';
import Button from '../../common/components/Button';
import Upload from '../../common/components/upload/Upload';
import Modal from '../../common/containers/Modal';
import Config from '../../config';
import Events from './Events';

class ImportFromWordButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      processing: false,
      successMessage: null,
      uploadUniqueKey: null,
      insertMode: 'replace',
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onInsertModeChange = this.onInsertModeChange.bind(this);
  }

  openModal() {
    console.log(this.state);
    this.setState((state) => ({
      ...state,
      showModal: true,
      uploadUniqueKey: new Date().getMilliseconds(),
    }));
  }

  closeModal() {
    this.setState((state) => ({
      ...state,
      showModal: false,
      successMessage: null,
    }));
  }

  onSuccess(html) {
    const { i18n } = this.props;

    const msg = i18n.t('O conteúdo atual será sobrescrito pelo conteúdo do arquivo Word. Deseja continuar?');
    if (!confirm(msg)) {
      this.closeModal();
      return;
    }
    this.props.editor.setData(html);

    this.setState((state) => ({
      ...state,
      successMessage: i18n.t('Documento importado com sucesso. O conteúdo foi inserido no editor.'),
    }));

    this.props.editor.fire(Events.SAVE_AND_COMPILE);
  }

  onInsertModeChange(e) {
    e.persist();
    this.setState((s) => ({ ...s, insertMode: e.target.value }));
  }

  render() {
    if (this.props.document && this.props.document.is_presentation) {
      return null;
    }

    if (!this.props.forceShow && this.props.document.days_since_creation > 1) {
      return null;
    }

    const { i18n } = this.props;

    return (
      <>
        <a role="button" onClick={this.openModal}>
          <i className="icon mdi mdi-upload" />
          <span className="hidden-md hidden-sm hidden-xs">
            &nbsp;
            {i18n.t('Importar Word')}
          </span>
        </a>

        <Modal
          title={i18n.t('Importar documento do Word')}
          show={this.state.showModal}
          onCancel={this.closeModal}
          isProcessing={this.state.processing}
          footer={
            <Button type="primary" size="lg" onClick={this.closeModal}>
              Fechar
            </Button>
          }
        >
          <AlertSuccess>{this.state.successMessage}</AlertSuccess>

          {!this.state.successMessage && (
            <>
              <Upload
                key={this.state.uploadUniqueKey}
                url={`${Config.apiHost}documents/${this.props.document.id}/import_word/`}
                onStart={() => this.setState((state) => ({ ...state, processing: true }))}
                onAllFinished={() => this.setState((state) => ({ ...state, processing: false }))}
                onSuccess={this.onSuccess}
                accept={['.doc', '.docx']}
              />

              <h4 className="text-danger">
                <i className="glyphicon glyphicon-warning-sign" />
                &nbsp;
                {i18n.t('Leia as instruções a seguir')}
              </h4>

              <ol>
                <li>
                  {i18n.t(
                    'Antes de enviar seu arquivo, remova os elementos pré-textuais como Capa, Folha de Rosto, listas e resumos.',
                  )}
                </li>
                <li>{i18n.t('Imagens com mais de 10Mb não serão importadas.')}</li>
                <li>{i18n.t('Após enviar, você precisa seguir alguns passos para compatibilizar seu documento')}</li>
                <ul>
                  <li>{i18n.t('Inserir o título e os autores nos campos específicos do FastFormat')}</li>
                  <li>{i18n.t('Insirir o texto do abtract/resumo nos campos do FastFormat')}</li>
                  <li>{i18n.t('Cadastrar/importar a bibliografia na sua conta do FastFormat')}</li>
                  <li>{i18n.t('Utilizar o mecanismo de citação automática do FastFormat')}</li>
                  <li>
                    <i>
                      {i18n.t(
                        'Fórmulas ainda não são suportadas nessa importação. Assim, utilize o editor de fórmulas do FastFormat para inserí-las manualmente.',
                      )}
                    </i>
                  </li>
                </ul>
              </ol>
            </>
          )}
        </Modal>
      </>
    );
  }
}

ImportFromWordButton.propTypes = {
  document: PropTypes.object.isRequired,
  editor: PropTypes.object.isRequired,
  forceShow: PropTypes.bool,
};

export default withNamespaces()(ImportFromWordButton);
