/* This configuration should be applied for every instance of CKEditor, unless
 * you need some specific configuration for a specific instance.
 */

import Config from '../../config';

window.gettext = function (text) {
  return text;
};

export default function configureCKEditor(debug, language, type) {
  CKEDITOR.timestamp = Config.ckeditorTimestamp;
  CKEDITOR.config.title = 'FastFormat';

  CKEDITOR.config.filebrowserImageBrowseUrl = 'this-is-only-to-force-show-the-dialog';
  CKEDITOR.config.filebrowserUploadUrl = 'this-is-only-to-force-show-the-dialog';

  CKEDITOR.config.skin = 'moono-lisa';
  CKEDITOR.config.mathJaxLib = '//cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.0/MathJax.js?config=TeX-AMS-MML_HTMLorMML';

  const customPluginsPath = `/lib/ckeditor/ckeditor_${CKEDITOR.version}_custom_plugins/`;
  const pluginFilename = 'plugin.js';

  const externalPlugins = [
    'fastformatFormat',
    'fastformatCitation',
    'fastformatComments',
    'fastformatFootnote',
    'fastformatImage',
    'fastformatLayout',
    'fastformatLink',
    // 'fastformatMathjax',
    // 'mathjax', // replaced by fastformatFormula
    'fastformatFormula',
    'fastformatNoIndent',
    'fastformatProofread',
    'fastformatSave',
    'fastformatTable',
    'fastformatTableResize',
    'fastformatZoom',
    'fastformatDisableGrammarly',
    'image2',
    'fastformatCleaner',
    type !== 'presentation' ? 'fastformatIncludePDF' : null,
    // 'fastformatCodesnippet', // replaced by fastformatCodeSnippetV2
    'fastformatCodeSnippetV2',
    'fastformatHideButtons',
  ].filter((pluginName) => !!pluginName);

  externalPlugins.forEach(function (name) {
    if (!CKEDITOR.plugins.externals[name]) {
      const path = customPluginsPath + name + '/';
      CKEDITOR.plugins.addExternal(name, path, pluginFilename);
      console.log('Add external plugin:', path);
    }
  });

  CKEDITOR.config.removePlugins = [
    'link',
    'elementspath',
    'save',
    'format',
    'codesnippet',
    'wsc',
    'scayt',
    'stylescombo', // Removed due to bug "TypeError: Cannot read property 'registered' of undefined", even though this plugin is not used in FastFormat
  ].join(',');

  if (type) {
    CKEDITOR.config.type = type;
  }

  CKEDITOR.config.specialChars = [
    '&lt;',
    '&gt;',
    '&le;',
    '&ge;',
    '&euro;',
    '&lsquo;',
    '&rsquo;',
    '&ldquo;',
    '&rdquo;',
    '&ndash;',
    '&mdash;',
    '&iexcl;',
    '&cent;',
    '&pound;',
    '&curren;',
    '&yen;',
    '&brvbar;',
    '&sect;',
    '&uml;',
    '&copy;',
    '&ordf;',
    '&laquo;',
    '&not;',
    '&reg;',
    '&macr;',
    '&deg;',
    '&sup2;',
    '&sup3;',
    '&acute;',
    '&micro;',
    '&para;',
    '&middot;',
    '&cedil;',
    '&sup1;',
    '&ordm;',
    '&raquo;',
    '&frac14;',
    '&frac12;',
    '&frac34;',
    '&iquest;',
    '&Agrave;',
    '&Aacute;',
    '&Acirc;',
    '&Atilde;',
    '&Auml;',
    '&Aring;',
    '&AElig;',
    '&Ccedil;',
    '&Egrave;',
    '&Eacute;',
    '&Ecirc;',
    '&Euml;',
    '&Igrave;',
    '&Iacute;',
    '&Icirc;',
    '&Iuml;',
    '&ETH;',
    '&Ntilde;',
    '&Ograve;',
    '&Oacute;',
    '&Ocirc;',
    '&Otilde;',
    '&Ouml;',
    '&times;',
    '&Oslash;',
    '&Ugrave;',
    '&Uacute;',
    '&Ucirc;',
    '&Uuml;',
    '&Yacute;',
    '&THORN;',
    '&szlig;',
    '&agrave;',
    '&aacute;',
    '&acirc;',
    '&atilde;',
    '&auml;',
    '&aring;',
    '&aelig;',
    '&ccedil;',
    '&egrave;',
    '&eacute;',
    '&ecirc;',
    '&euml;',
    '&igrave;',
    '&iacute;',
    '&icirc;',
    '&iuml;',
    '&eth;',
    '&ntilde;',
    '&ograve;',
    '&oacute;',
    '&ocirc;',
    '&otilde;',
    '&ouml;',
    '&divide;',
    '&oslash;',
    '&ugrave;',
    '&uacute;',
    '&ucirc;',
    '&uuml;',
    '&yacute;',
    '&thorn;',
    '&yuml;',
    '&OElig;',
    '&oelig;',
    '&#372;',
    '&#374',
    '&#373',
    '&#375;',
    '&sbquo;',
    '&#8219;',
    '&bdquo;',
    '&hellip;',
    '&trade;',
    '&#9658;',
    '&bull;',
    '&rarr;',
    '&rArr;',
    '&hArr;',
    '&diams;',
    '&asymp;',
  ];

  const capital_greek_letters = [
    '&Gamma;',
    '&Delta;',
    '&Theta;',
    '&Lambda;',
    '&Xi;',
    '&Pi;',
    '&Sigma;',
    '&Upsilon;',
    '&Phi;',
    '&Psi;',
    '&Omega;',
  ];

  const small_greek_letters = [
    '&alpha;',
    '&beta;',
    '&gamma;',
    '&delta;',
    '&epsilon;',
    '&zeta;',
    '&eta;',
    '&theta;',
    '&iota;',
    '&kappa;',
    '&lambda;',
    '&mu;',
    '&nu;',
    '&xi;',
    '&pi;',
    '&rho;',
    '&sigmaf;',
    '&sigma;',
    '&tau;',
    '&upsilon;',
    '&phi;',
    '&chi;',
    '&psi;',
    '&omega;',
  ];

  CKEDITOR.config.specialChars = CKEDITOR.config.specialChars.concat(capital_greek_letters).concat(small_greek_letters);
  CKEDITOR.config.entities_latin = false;
  CKEDITOR.config.forcePasteAsPlainText = true;

  CKEDITOR.config.removeDialogTabs = [
    'image:advanced',
    'table:advanced',
    'tableProperties:advanced',
    'link:target',
    'link:advanced',
    'link:upload',
  ].join(';');

  CKEDITOR.config.uiColor = '#FAFAFA';

  CKEDITOR.config.resize_enabled = false;

  CKEDITOR.config.menu_groups = [
    'clipboard',
    'form',
    'tablecell',
    'tablerow',
    'tablecolumn',
    'table',
    //Removed: tablecellproperties
    'anchor',
    'link',
    'image',
    'flash',
    'checkbox',
    'radio',
    'textfield',
    'hiddenfield',
    'imagebutton',
    'button',
    'select',
    'textarea',
    'div',
  ].join(',');

  CKEDITOR.config.colorButton_enableMore = false;
  CKEDITOR.config.colorButton_colors = [
    /*'000',*/ '800000',
    '8B4513',
    '2F4F4F',
    '008080',
    '000080',
    '4B0082',
    '696969',
    //'B22222', 'A52A2A', 'DAA520', '006400', '40E0D0', '0000CD', '800080', '808080',
    'F00',
    'FF8C00',
    'FFD700',
    '008000',
    '0FF',
    '00F',
    'EE82EE',
    'A9A9A9',
    'FFA07A',
    'FFA500',
    'FFFF00',
    '00FF00',
    'AFEEEE',
    'ADD8E6',
    'DDA0DD',
    'D3D3D3',
    //'FFF0F5', 'FAEBD7', 'FFFFE0', 'F0FFF0', 'F0FFFF', 'F0F8FF', 'E6E6FA', 'FFF'
  ].join(',');

  if (language !== undefined) {
    CKEDITOR.config.language = language;
    String.locale = language; // for l10n.js
  }
}
