import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../common/containers/Modal';
import URLS from '../../urls';
import Config from '../../config';
import Ajax from '../../common/ajax';
import Row from '../../common/containers/Row';
import Col from '../../common/containers/Col';
import Events from './Events';
import { hasProofread, hasSpellchecker } from '../../context/global';
import { withNamespaces } from 'react-i18next';
import Button from '../../common/components/Button';
import Panel from '../../common/containers/Panel';

class ProofreaderPitch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      modalDidShow: false,
      fetchesCount: 0,
      report: null,
      bestPlan: null,
      visibility: 'visible',
    };
    this.fetchReport = this.fetchReport.bind(this);
    this.saveAndBuy = this.saveAndBuy.bind(this);
    this.blink = this.blink.bind(this);
    this.compilationCount = 1;

    this.maxBlink = 3;
    this._mounted = false;
  }

  componentDidMount() {
    this._mounted = true;
    if (hasSpellchecker() && hasProofread()) {
      return;
    }

    this.fetchReport();

    this.props.editor.on(Events.AFTER_COMPILATION_COMPLETE, () => {
      this.compilationCount++;
      this.createReport();
    });
    // this.fetchBestPlan();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  fetchReport() {
    const { id } = this.props.document;
    const url = `${Config.apiHost}proofread/report/?document=${id}`;

    Ajax.get(url).done((report) => {
      if (!this._mounted) {
        return;
      }

      this.setState((state) => ({ ...state, report }));
      if (report) {
        if (report.running) {
          setTimeout(this.fetchReport, 3000);
        } else {
          this.blink();
        }
      }

      // Show modal for the first time to get attention.
      if (this.compilationCount % 10 === 0 && !this.state.modalDidShow) {
        this.setState((state) => ({ ...state, showModal: true }));
      }
    });
  }

  createReport() {
    const { id } = this.props.document;
    const url = `${Config.apiHost}proofread/report/?document=${id}`;
    Ajax.post(url).done(() => this.fetchReport());
  }

  // fetchBestPlan() {
  //     const url = `${Config.apiHost}plans/all`;
  //     Ajax.get(url).done(plans => {
  //         let bestPlan;
  //         plans.forEach(p => {
  //             if (!bestPlan || p.price > bestPlan.price) {
  //                 bestPlan = p;
  //             }
  //         });
  //         this.setState(state => ({...state, bestPlan}));
  //     });
  // }

  saveAndBuy() {
    this.props.editor.resetDirty();
    document.location = URLS.plans;
  }

  blink(count = 0) {
    if (!this._mounted) {
      return;
    }

    if (count < this.maxBlink) {
      this.setState((state) => ({
        ...state,
        visibility: state.visibility === 'visible' ? 'hidden' : 'visible',
      }));
      setTimeout(() => this.blink(count + 1), 600);
    } else {
      this.setState((state) => ({
        ...state,
        visibility: 'visible',
      }));
    }
  }

  render() {
    const { report, bestPlan } = this.state;

    if (!report || !report.detail.length) {
      return null;
    }

    const { i18n } = this.props;

    const table1 = report.detail.slice(0, 6);
    const table2 = report.detail.slice(6, report.detail.length);

    return (
      <>
        <li
          id="proofreader-pitch"
          className="dropdown"
          title={i18n.t('Seu texto possui problemas de escrita')}
          data-toggle="tooltip"
          data-placement="bottom"
        >
          <a
            role="button"
            onClick={() =>
              this.setState((s) => ({
                ...s,
                showModal: true,
                modalDidShow: true,
              }))
            }
            style={{ visibility: this.state.visibility }}
          >
            <span className="text-danger">
              <i className="mdi mdi-alert-polygon" style={{ fontSize: '24px', verticalAlign: 'middle' }} />{' '}
              <b>{report.total}</b>
            </span>
          </a>
        </li>

        <Modal
          title={`${report.total} ${
            report.total > 1 ? i18n.t('problemas de escrita encontrados') : i18n.t('problema de escrita encontrado')
          }`}
          show={this.state.showModal}
          onCancel={() => this.setState((s) => ({ ...s, showModal: false }))}
        >
          <Row>
            <Col md={table2.length > 0 ? 6 : 12}>
              <table className="table table-condensed">
                <tbody>
                  {table1.map((tuple) => (
                    <tr key={tuple[0]}>
                      <td>
                        <b>{tuple[0]}</b>
                      </td>
                      <td>{tuple[1]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Col>

            {table2.length > 0 && (
              <Col md={6}>
                <table className="table table-condensed">
                  <tbody>
                    {table2.map((tuple) => (
                      <tr key={tuple[0]}>
                        <td>
                          <b>{tuple[0]}</b>
                        </td>
                        <td>{tuple[1]}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Col>
            )}
          </Row>

          <Panel fullColor="gray">
            {i18n.t(
              'Esses problemas podem ser facilmente corrigidos utilizando nossas ferramentas de verificação. Adquira um plano com suporte a essas ferramentas para corrigir esses problemas.',
            )}
          </Panel>

          <p className="text-center">
            <Button type="success" size="lg" onClick={this.saveAndBuy}>
              {i18n.t('Escolher plano')}
            </Button>
          </p>
        </Modal>
      </>
    );
  }
}

ProofreaderPitch.propTypes = {
  document: PropTypes.object.isRequired,
  editor: PropTypes.object.isRequired,
};

export default withNamespaces()(ProofreaderPitch);
