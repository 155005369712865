import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import Ajax from '../../../common/ajax';
import { AlertError, AlertInfo } from '../../../common/components/Alert';
import Button from '../../../common/components/Button';
import ButtonDropdown from '../../../common/components/ButtonDropdown';
import LazyImage from '../../../common/components/LazyImage';
import SearchInput from '../../../common/components/SearchInput';
import Col from '../../../common/containers/Col';
import Modal from '../../../common/containers/Modal';
import Row from '../../../common/containers/Row';
import Waiting from '../../../common/containers/Waiting';
import storage from '../../../common/storage';
import Config from '../../../config';
import ImageEditor from './ImageEditor';
import ImageUpload from './ImageUpload';
import LocaleUtils from '../../../common/LocaleUtils';

class MediaBrowser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      images: [],
      imagesCopy: [],
      search: '',
      sorting: 'asc',
      sortingAttribute: 'filename',
      showUpload: false,
      errorMessages: [],
      thumbsRenderTimer: new Date().getTime(),
    };
    this._mounted = null;
    this.removeImage = this.removeImage.bind(this);
    this.fetchImages = this.fetchImages.bind(this);
    this.onChangeSearch = debounce(this.onChangeSearch.bind(this), 300);
    this.onSortingChange = this.onSortingChange.bind(this);
    this.documentId = this.props.documentId;
    this.closeUpload = this.closeUpload.bind(this);
    this.reloadEditedImage = this.reloadEditedImage.bind(this);
    this.cacheControlKey = 'imagesCacheControl';
  }

  componentDidMount() {
    this._mounted = true;
    this.fetchImages();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  fetchImages() {
    this.setState((state) => ({ ...state, processing: true }));
    const url = `${Config.apiHost}document-media/?document=${this.documentId}&types=${this.props.mediaTypes.join(',')}`;
    Ajax.get(url)
      .done((images) => {
        this._mounted &&
          this.setState((state) => ({
            ...state,
            images,
            imagesCopy: images,
            processing: false,
            thumbsRenderTimer: new Date().getTime(),
          }));
      })
      .fail(() => {
        this._mounted && this.setState((state) => ({ ...state, processing: false }));
      });
  }

  removeImage(img) {
    const { i18n } = this.props;
    const msg = i18n.t('Confirma a remoção do arquivo') + ` "${img.filename}"?`;
    if (!confirm(msg)) {
      return;
    }

    this.setState((state) => ({ ...state, processing: true }));
    const url = `${Config.apiHost}document-media/${img.id}`;
    Ajax.delete_(url)
      .done(() => {
        this.fetchImages();
      })
      .always(() => {
        this._mounted && this.setState((state) => ({ ...state, processing: false }));
      })
      .fail(() => {
        alert(i18n.t('Não foi possível remover a imagem.'));
      });
  }

  onChangeSearch(value) {
    if (!value || !value.trim()) {
      this.setState((state) => ({ ...state, images: state.imagesCopy }));
    } else {
      this.setState((state) => ({ ...state, processing: true }));
      const images = [];
      this.state.images.forEach((img) => {
        if (img.filename.toLowerCase().indexOf(value.toLowerCase()) > -1) {
          images.push(img);
        }
      });
      this.setState((state) => ({ ...state, images, processing: false }));
    }
  }

  onSortingChange(sorting = 'asc', sortingAttribute = 'filename') {
    this.setState((state) => ({ ...state, processing: true }));
    const images = [...this.state.images];

    if (sorting === 'asc') {
      images.sort((a, b) => a[sortingAttribute].toLowerCase().localeCompare(b[sortingAttribute].toLowerCase()));
    } else {
      images.sort((a, b) => b[sortingAttribute].toLowerCase().localeCompare(a[sortingAttribute].toLowerCase()));
    }

    this.setState((state) => ({
      ...state,
      images,
      sorting,
      sortingAttribute,
      processing: false,
    }));
  }

  closeUpload() {
    this.setState((state) => ({ ...state, showUpload: false }));
    this.fetchImages();
  }

  onImageClick(img) {
    this.props.onImageClick(img);
  }

  reloadEditedImage(editedImage) {
    const images = [];
    this.state.images.forEach((img) => {
      if (img.thumbnail === editedImage.thumbnail) {
        img.thumbnail += '?' + new Date().getTime();
      }
      images.push(img);
    });
    this.setState((state) => ({
      ...state,
      images,
    }));

    // Prevent loading from cache next time this component opens.
    storage.setItem(this.cacheControlKey, parseInt(storage.getItem(this.cacheControlKey, 0)) + 1);
  }

  renderSortButton() {
    const { i18n } = this.props;
    const sortOptions = {
      filename: i18n.t('Ordem por nome'),
      creation_date: i18n.t('Ordem por data criação'),
    };
    const currentLabel = sortOptions[this.state.sortingAttribute];

    return (
      <ButtonDropdown
        label={
          this.state.sorting === 'asc' ? (
            <>
              <i className="icon mdi mdi-sort-amount-asc" />{' '}
              <span className="hidden-xs hidden-sm">{currentLabel + ' ' + i18n.t('crescente')}</span>
            </>
          ) : (
            <>
              <i className="icon mdi mdi-sort-amount-desc" />{' '}
              <span className="hidden-xs hidden-sm">{currentLabel + ' ' + i18n.t('decrescente')}</span>
            </>
          )
        }
        size="lg"
        type="default"
        block={true}
      >
        {Object.entries(sortOptions).map((entry) => (
          <>
            <li>
              <a role="button" onClick={() => this.onSortingChange('asc', entry[0])}>
                {entry[1] + ' ' + i18n.t('crescente')}
              </a>
            </li>
            <li>
              <a role="button" onClick={() => this.onSortingChange('desc', entry[0])}>
                {entry[1] + ' ' + i18n.t('decrescente')}
              </a>
            </li>
          </>
        ))}
      </ButtonDropdown>
    );
  }

  renderImages() {
    const { i18n } = this.props;
    const images = [...this.state.images];

    if ((!images || images.length === 0) && !this.state.processing) {
      return (
        <Row style={{ marginTop: '20px' }}>
          <Col md={8} mdOffset={2}>
            <AlertInfo>{i18n.t('Nenhum arquivo encontrado.')}</AlertInfo>
          </Col>
        </Row>
      );
    }

    // Make fixed row height.
    const subArrays = [];
    while (images.length > 0) {
      subArrays.push(images.splice(0, 4));
    }

    return subArrays.map((images, idx) => (
      <Row key={idx}>
        {images.map((img) => (
          <Col key={img.thumbnail} sm={3}>
            <div className="thumbnail">
              <LazyImage
                src={img.thumbnail + '?t=' + parseInt(storage.getItem(this.cacheControlKey, 0))}
                alt={i18n.t('Clique para selecionar')}
                title={i18n.t('Clique para selecionar')}
                onClick={() => this.onImageClick(img)}
                style={{ maxHeight: '200px', cursor: 'pointer' }}
              />
              <div className="caption">
                <p style={{ wordWrap: 'break-word', marginBottom: '5px' }}>
                  {img.filename}{' '}
                  <small className="text-muted" style={{ whiteSpace: 'nowrap' }}>
                    {LocaleUtils.calendar(img.creation_date)}
                  </small>
                </p>
                <div className="btn-toolbar">
                  <div className="btn-group btn-group-xs">
                    <ImageEditor
                      image={img}
                      onImageSaved={() => this.reloadEditedImage(img)}
                      onImageCreated={() => this.fetchImages()}
                    />
                    <a
                      className="btn btn-default"
                      href={`${Config.djangoHost}${img.file_for_editor.substring(1)}`}
                      target="_blank"
                    >
                      <span className="icon mdi mdi-download" /> {i18n.t('Baixar')}
                    </a>
                    <button onClick={() => this.removeImage(img)} className="btn btn-default" title={i18n.t('Excluir')}>
                      <span className="icon mdi mdi-delete text-danger" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    ));
  }

  render() {
    const { i18n, mediaTypes } = this.props;
    const ASC = i18n.t('Ordem alfabética crescente');
    const DESC = i18n.t('Ordem alfabética decrescente');
    let acceptedExtensions = [];
    let title = i18n.t('Selecionar imagem');

    if (mediaTypes.indexOf('pdf') > -1) {
      acceptedExtensions.push('.pdf');
      title = i18n.t('Selecionar arquivo');
    }

    if (mediaTypes.indexOf('image') > -1) {
      acceptedExtensions = acceptedExtensions.concat(['.jpg', '.jpeg', '.png', '.eps']);
    }

    const insertButton = (
      <Button
        size="lg"
        block={true}
        onClick={() =>
          this.setState((state) => ({
            ...state,
            showUpload: true,
            errorMessages: [],
          }))
        }
      >
        {i18n.t('Incluir')}
      </Button>
    );

    return (
      <Modal
        show={true}
        width="full"
        bodyMinHeight="80vh"
        title={title}
        onCancel={this.props.onClose}
        style={{ zIndex: 10020 }}
      >
        <Row>
          <Col xs={4}>
            <SearchInput value={this.state.search} onChange={this.onChangeSearch} />
          </Col>
          <Col xs={4}>{this.renderSortButton()}</Col>
          <Col xs={4}>{insertButton}</Col>
        </Row>

        <br />

        {this.state.errorMessages.map((obj) => (
          <AlertError>
            <b>{obj.filename}:</b> {obj.message}
          </AlertError>
        ))}

        <br />

        <Waiting key={this.state.thumbsRenderTimer} isProcessing={this.state.processing}>
          {this.renderImages()}
        </Waiting>

        {this.state.showUpload && (
          <ImageUpload
            onClose={this.closeUpload}
            onAllFinished={this.closeUpload}
            onError={(jqXHR, obj) =>
              this.setState((s) => ({
                ...s,
                errorMessages: [obj, ...s.errorMessages],
              }))
            }
            fetchURL={`${Config.apiHost}document-media/?document=${this.props.documentId}&types=${mediaTypes.join(
              ',',
            )}`}
            uploadURL={`${Config.apiHost}document-media/upload/?document=${
              this.props.documentId
            }&types=${mediaTypes.join(',')}`}
            acceptedExtensions={acceptedExtensions}
          />
        )}
      </Modal>
    );
  }
}

MediaBrowser.propTypes = {
  documentId: PropTypes.number.isRequired,
  editor: PropTypes.object.isRequired,
  mediaTypes: PropTypes.arrayOf(PropTypes.oneOf(['image', 'pdf'])),
  onImageClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

MediaBrowser.defaultProps = {
  mediaTypes: ['image', 'pdf'],
};

MediaBrowser = withNamespaces()(MediaBrowser);

export default MediaBrowser;
