import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import sum from 'hash-sum/hash-sum';
// import '../../../../vendor/bootstrap-tour/css/bootstrap-tour.css';
import Config from '../../../config';
import storage from '../../../common/storage';

import Tour from 'bootstrap-tour';
import Tip from '../../../common/components/onboarding/Tip';

class EditorTour extends Component {
  constructor(props) {
    super(props);
    this.startTour = this.startTour.bind(this);
    const { i18n } = this.props;

    const steps = [
      {
        element: '#title-menu-item',
        title: i18n.t('Dados gerais do documento'),
        content: i18n.t(
          'Utilize estes botões para inserir as informações de capa, autores, acrônimos e símbolos.'
        ),
        placement: 'right',
      },
      {
        element: '#id-content-menu-item',
        title: i18n.t('Texto principal do documento'),
        content: i18n.t(
          'Clique neste item para editar o texto principal do seu documento.'
        ),
        placement: 'right',
      },
      {
        element: '.cke_button__fastformatsave',
        title: i18n.t('Salve para gerar o documento'),
        content: i18n.t(
          'Use este botão para salvar seu texto e também para gerar o documento final.'
        ),
        placement: 'bottom',
      },
      {
        element: '.cke_combo__fastformatformat',
        title: i18n.t('Formatando capítulos e seções'),
        content: i18n.t(
          'Utilize essa opção para formatar nomes capítulos e seções no seu texto.'
        ),
        placement: 'bottom',
      },
      {
        element: '.cke_button__fastformatcitation',
        title: i18n.t('Inserindo citações'),
        content: i18n.t('Use este botão para inserir citações no texto.'),
        placement: 'bottom',
      },
      {
        element: '.cke_button__fastformathidebuttons',
        title: i18n.t('Mais ferramentas de texto'),
        content: i18n.t(
          'Use este botão para exibir mais ferramentas de texto.'
        ),
        placement: 'bottom',
      },
      {
        element: '.right-pane',
        title: i18n.t('Visualizando o resultado'),
        content: i18n.t(
          'No lado direito, você pode visualizar o PDF resultante do seu documento. Clique e arraste a barra vertical para alterar o tamanho do pré-visualização.'
        ),
        placement: 'left',
      },
      // {
      //     element: "#download-pdf",
      //     title: i18n.t("Download do PDF"),
      //     content: i18n.t("Clique para baixar o PDF do seu documento."),
      //     placement: 'bottom'
      // },
      {
        element: '#properties-menu-item',
        title: i18n.t('Propriedades do documento e modelo'),
        content: i18n.t(
          'Clique para editar as propriedades do seu documento. Você também pode editar as informações exigidas pelo modelo escolhido.'
        ),
        placement: 'bottom',
      },
      {
        element: '#import-word-toolbar',
        title: i18n.t('Importar texto de arquivo do MS Word.'),
        content: i18n.t(
          'Depois de importar seu arquivo do Word, você precisará executar algumas modificações para estar em conformidade com as regras do FastFormat. Na maioria dos casos, você precisará pegar algumas partes de texto e colocar nos locais certos de FastFormat.'
        ),
        placement: 'bottom',
      },
      {
        element: '#help_videos_button',
        title: i18n.t('Assista a vídeos tutoriais'),
        content: i18n.t(
          'Muitos vídeos tutoriais para ajudar você a começar com o FastFormat.'
        ),
        placement: 'bottom',
      },
      {
        element: '#id-document-menu',
        title: i18n.t('Mais opções para o seu documento'),
        content: i18n.t(
          'Neste menu, você verá mais opções para lidar com seu documento.'
        ),
        placement: 'left',
      },
    ];

    const tourStyle =
      'background-color: rgb(95, 153, 245); color: white; border-color: rgb(95, 153, 245);';

    this.tour = new Tour({
      name: `ff-tour-${sum(JSON.stringify(steps))}`,
      debug: !Config.isProduction,
      storage: window.localStorage,
      steps,
      template: `
                <div class="popover" role="tooltip">
                    <div class="arrow"/>
                    <h3 class="popover-title" style="${tourStyle} font-weight: bold;"/>
                    <div class="popover-content" style="${tourStyle}"/>
                    <div class="popover-navigation" style="${tourStyle}">
                        <div class="btn-group" style="margin: 9px 14px;">
                            <button class="btn btn-xs btn-default" data-role="prev">
                                &laquo; ${i18n.t('Anterior')}
                            </button>
                            <button class="btn btn-xs btn-default" data-role="next">
                                ${i18n.t('Próximo')} &raquo;
                            </button>
                            <button class="btn btn-xs btn-default"
                                    data-role="pause-resume"
                                    data-pause-text="Pause"
                                    data-resume-text="Resume">
                                Pause
                            </button>
                        </div>
                        <button class="btn btn-xs btn-default" data-role="end">
                            ${i18n.t('Finalizar')}
                        </button>
                    </div>
                </div>
            `,
    });
  }

  startTour() {
    this.tour.init();
    const force = storage.getItem('restartTour') === '1';
    this.tour.start(force);
  }

  componentWillUnmount() {
    if (!this.tour.ended()) {
      storage.setItem('restartTour', '1');
      this.tour.end();
    } else {
      storage.removeItem('restartTour');
    }
  }

  render() {
    const { i18n } = this.props;
    return (
      <Tip
        title={`${i18n.t('Antes de começar')}...`}
        tips={[
          [
            i18n.t(
              'O modelo escolhido vem pré-configurado com os parâmetros estabelecidos pela norma do mesmo.'
            ),
            i18n.t(
              'Entretanto, você pode alterar alguns desses parâmetros nas opções disponíveis no menu'
            ),
            i18n.t('Configurações'),
          ].join(' '),
          i18n.t(
            'Para ter uma melhor experiência, siga os balões de dicas que aparecerão em seguida ;)'
          ),
        ]}
        onClose={this.startTour}
        defaultShowAgain={false}
      />
    );
  }
}

export default withNamespaces()(EditorTour);
